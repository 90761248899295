<template>
  <v-row justify="center">
    <v-card
      flat
      style="width: 1900px; height: 70px; position: fixed; top: 0; z-index: 5"
    >
      <v-row style="margin-top: 10px" justify="center">
        <div style="margin-top: -5px">
          <table id="menutable">
            <tr>
              <td>
                <v-img
                  width="150px"
                  src="../assets/argong_menu_logo.png"
                ></v-img>
              </td>
              <td>
                <div style="width: 200px"></div>
              </td>
              <td>
                <button @click="goid()" plain id="id" class="menubtnclicked">
                  계정 관리
                </button>
              </td>
              <td>
                <button plain id="dash" class="menubtn">대시보드</button>
              </td>
              <td>
                <button plain id="alim" class="menubtn">알림톡</button>
              </td>
              <td>
                <div style="width: 200px"></div>
              </td>
              <td>
                <p
                  @click="logout()"
                  style="
                    margin-left: 100px;
                    cursor: pointer;
                    font-family: notosanslight;
                    font-weight: bold;
                    margin-top: 15px;
                  "
                >
                  로그아웃
                </p>
              </td>
            </tr>
          </table>
        </div>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
    },
    goid() {
      if (this.$router.currentRoute.path == "/idmanage") {
        return;
      }
      const btn1 = document.getElementById("id");
      const btn2 = document.getElementById("dash");
      const btn3 = document.getElementById("alim");

      btn1.className = "menubtnclicked";
      btn2.className = "menubtn";
      btn3.className = "menubtn";

      this.$router.push({ path: "/idmanage" });
    },
    godash() {
      const btn1 = document.getElementById("id");
      const btn2 = document.getElementById("dash");
      const btn3 = document.getElementById("alim");

      btn1.className = "menubtn";
      btn2.className = "menubtnclicked";
      btn3.className = "menubtn";
    },
    goalim() {
      const btn1 = document.getElementById("id");
      const btn2 = document.getElementById("dash");
      const btn3 = document.getElementById("alim");

      btn1.className = "menubtn";
      btn2.className = "menubtn";
      btn3.className = "menubtnclicked";
    },
  },
};
</script>

<style>
@font-face {
  font-family: "notosans";
  src: url("../assets/font/NotoSansKR-Bold.otf");
}

@font-face {
  font-family: "notosanslight";
  src: url("../assets/font/NotoSansKR-Light.otf");
}

#menutable {
  width: 1800px;
  height: 70px;
}

#optionmenutable {
  width: 20%;
  height: 80px;
  padding: 8px;
}

#infobtn {
  font-family: "notosans";
  font-size: 17px;
  color: rgb(90, 90, 90);
}

.menubtn {
  color: rgb(90, 90, 90);
  font-family: "notosans";
  font-size: 18px;
  width: 150px;
  height: 10px;
}

.menubtnclicked {
  color: rgb(20, 158, 236);
  text-decoration: underline;
  font-family: "notosans";
  font-size: 20px;
  width: 150px;
  height: 20px;
}

#settingbtn {
  float: left;
  width: 50px;
  height: 50px;
  background-image: url("../assets/Gear.png");
  background-size: 50%;
  background-position: center;
}

#optionbtnone {
  margin-top: 5px;
  font-size: 18px;
  width: 200px;
  height: 100px;
  font-family: "notosans";
  background-color: white;
}

#optionbtntwo {
  font-size: 18px;
  width: 200px;
  height: 100px;
  font-family: "notosans";
  background-color: white;
}

#helpoptionbtn {
  width: 250px;
  height: 100px;
  background-color: white;
  font-family: "notosans";
  font-size: 18px;
}

#helpbtnicon {
  width: 15px;
  margin-top: 3px;
}
</style>