<template>
  <div>
    <v-row style="font-family: notosanslight" justify="center">
      <v-btn
        @click="goall()"
        id="selected"
        width="130"
        height="40"
        style="
          margin-right: 20px;
          font-weight: bold;
          font-size: 18px;
          color: white;
        "
        outlined
        >전체조회</v-btn
      >
      <v-btn
        @click="goclass()"
        id="unselected"
        width="130"
        height="40"
        style="font-weight: bold; font-size: 18px; color: white"
        outlined
        >학급조회</v-btn
      >
    </v-row>
    <div>
      <allidview v-show="screenset == 0"></allidview>
      <contractview v-show="screenset == 1"></contractview>
    </div>
    <v-row justify="center">
      <div style="height: 30px"></div>
    </v-row>
  </div>
</template>

<script>
import allidview from "./idview/allidview.vue";
import contractview from "./idview/schoolidview.vue";

export default {
  data() {
    return {
      btn1: null,
      btn2: null,
      screenset: 0,
    };
  },
  components: {
    allidview,
    contractview,
  },
  async created() {},
  mounted() {},
  methods: {
    goall() {
      if (this.screenset == 0) {
        return;
      }
      this.screenset = 0;
      const btn1 = document.getElementById("selected");
      const btn2 = document.getElementById("unselected");

      btn1.id = "unselected";
      btn2.id = "selected";
      return;
    },
    goclass() {
      if (this.screenset == 1) {
        return;
      }
      this.screenset = 1;
      const btn1 = document.getElementById("selected");
      const btn2 = document.getElementById("unselected");

      btn1.id = "unselected";
      btn2.id = "selected";
      return;
    },
  },
};
</script>

<style>
@font-face {
  font-family: "notosanslight";
  src: url("../assets/font/NotoSansKR-Light.otf");
}

#contenttd {
  padding: 20px;
  border: 1px solid rgb(90, 90, 90);
  background-color: rgb(240, 240, 240);
  font-weight: bold;
}

#contenttdtd {
  padding: 20px;
  border: 1px solid rgb(90, 90, 90);
  background-color: white;
}

#selected {
  background-color: #0e7bcb;
}

#unselected {
  background-color: #b9b9b9;
}
</style>