<template>
  <div>
    <idmanager />
  </div>
</template>

<script>
// @ is an alias to /src
import idmanager from "../components/idManager.vue";

export default {
  name: "HomeView",
  components: {
    idmanager,
  },
};
</script>
