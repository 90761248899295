<template><div></div></template>

<script>
export default {
  created() {
    this.$router.replace({ path: "/idmanage" });
    return;
  },
};
</script>

<style>
</style>