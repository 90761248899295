<template>
  <div style="margin-top: 25vh; font-family: notosans" @keyup.enter="login()">
    <v-row justify="center">
      <p style="font-size: 25px; color: rgb(20, 158, 236)">
        알공 English Planet
      </p>
    </v-row>
    <v-row style="margin-top: -9px" justify="center">
      <p style="font-size: 27px; color: rgb(20, 158, 236)">관리자 페이지</p>
    </v-row>
    <v-row justify="center">
      <div>
        <br />
        <img width="100px" src="../assets/Astronaut.png" />
      </div>
    </v-row>
    <v-row justify="center">
      <div>
        <br />
        <v-row style="width: 300px" justify="center">
          <v-text-field v-model="id" max-width placeholder="ID"></v-text-field>
        </v-row>
        <v-row style="width: 300px" justify="center">
          <v-text-field
            v-model="pw"
            type="password"
            max-width
            placeholder="PW"
          ></v-text-field>
        </v-row>
      </div>
    </v-row>
    <v-row justify="center">
      <div align="center" style="width: 300px; margin-left: 25px">
        <v-row justify="left">
          <br />
          <div style="margin-top: 5px">
            <div style="float: left">
              <br />
              <input v-model="check" type="checkbox" />
            </div>
            <div style="float: left; margin-left: 10px">
              <br />
              <p>로그인 상태 유지</p>
            </div>
          </div>
        </v-row>
      </div>
    </v-row>

    <v-row justify="center">
      <div>
        <br />
        <v-btn @click="login()" id="loginbtn">LOGIN</v-btn>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      pw: "",
      check: false,
    };
  },
  methods: {
    login() {
      const id = this.id.trim();
      const password = this.pw.trim();
      const check = this.check;
      if (id == "" || password == "") {
        this.$store.state.error.msg = "로그인 정보를 입력해주세요!";
        this.$store.state.error.errorStatus = true;
        return;
      }
      this.$store.dispatch("LOGIN", { id, password, check });
    },
  },
};
</script>

<style>
@font-face {
  font-family: "notosans";
  src: url("../assets/font/NotoSansKR-Bold.otf");
}

#loginbtn {
  width: 300px;
  height: 50px;
  background-color: rgb(20, 158, 236);
  color: white;
  font-size: 18px;
}
</style>