<template>
  <Login />
</template>

<script>
import Login from '../components/LoginComponent.vue'
export default {
  components:{
    Login
  }
}
</script>

<style>

</style>