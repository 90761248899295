import { instance, instanceAuth } from './url'

const login = async (data) => {
  return await instance
    .post('/users/login', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getidlist = async () => {
  return await instanceAuth
    .get('/manages/all-user', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getcontractlist = async () => {
  return await instanceAuth
    .get('/manages/all-contract', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

export { getidlist, login, getcontractlist }
