import Vue from 'vue'
import VueRouter from 'vue-router'
import idview from '../views/idview.vue'
import loginview from '../views/LoginPageView.vue'
import defaultview from '../views/SeperateRouteView.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'default',
    component: defaultview,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: loginview
  },
  {
    path: '/idmanage',
    name: 'idview',
    component: idview,
    meta: {
      auth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters.logCheck) {
    next('/login')
    return
  }
  next()
})

export default router
