import {
  saveCookie,
  nonSaveCookie,
  deleteCookie,
  getSaveCookie
} from '../cookie/cookie'
import router from '../router/index'

const defaultmutation = async (state, res) => {}

const SET_LOGIN = async (state, res) => {
  state.loading = false
  if (res.test.data.data == -1) {
    state.error.msg = '존재하지 않는 아이디입니다.'
    state.error.code = res.code
    state.error.result = false
    state.error.errorStatus = true
  } else if (res.test.data.data == -2) {
    state.error.msg = '비밀번호가 잘못되었습니다.'
    state.error.code = res.code
    state.error.result = false
    state.error.errorStatus = true
  } else if (res.test.data.data == -3) {
    state.error.msg = '계약 기간이 종료된 아이디입니다.'
    state.error.code = res.code
    state.error.result = false
    state.error.errorStatus = true
  } else if (res.test.data.data == -4) {
    state.error.msg = '서버 점검중입니다.'
    state.error.code = res.code
    state.error.result = false
    state.error.errorStatus = true
  } else if (res.test.data.data == -5) {
    state.error.msg = '교사 계정으로 로그인해주세요.'
    state.error.code = res.code
    state.error.result = false
    state.error.errorStatus = true
  } else {
    if (res.check == true) {
      saveCookie('token2', res.test.data.data.token)
    } else {
      nonSaveCookie('token2', res.test.data.data.token)
    }
    state.token = getSaveCookie('token2')
    router.replace({ path: '/idmanage' })
  }
}

const SET_LOGOUT = async () => {
  deleteCookie('token2')
  router.go('/login')
}
export { defaultmutation, SET_LOGIN, SET_LOGOUT }
