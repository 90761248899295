<template>
  <div>
    <v-row style="margin-top: 30px" justify="center">
      <div style="width: 300px">
        <v-select
          v-model="selectindex"
          @change="changeallselect()"
          :items="allselectlist"
          :item-text="'text'"
          :item-value="'value'"
          outlined
        ></v-select>
      </div>
    </v-row>
    <v-row justify="center" style="margin-top: 30px">
      <table
        style="border-spacing: 0; width: 1500px; font-family: notosanslight"
      >
        <tr>
          <td id="contenttd">학교명</td>
          <td id="contenttd">학교코드</td>
          <td id="contenttd">학년</td>
          <td id="contenttd">반</td>
          <td id="contenttd">
            <div>
              시작 일자
              <div style="float: right">
                <v-img
                  @click="sortbystart()"
                  width="17px"
                  src="../../assets/sort_icon.png"
                ></v-img>
              </div>
            </div>
          </td>
          <td id="contenttd" style="text-align: center">
            <div style="float: center">
              종료 일자
              <div style="float: right">
                <v-img
                  @click="sortbyend()"
                  width="17px"
                  src="../../assets/sort_icon.png"
                ></v-img>
              </div>
            </div>
          </td>
        </tr>
        <tr v-for="(id, i) in list" :key="i">
          <td id="contenttdtd">{{ id.schoolName || "X" }}</td>
          <td id="contenttdtd">{{ id.schoolCode || "X" }}</td>
          <td id="contenttdtd">{{ id.grade || "X" }}</td>
          <td id="contenttdtd">{{ id.classNumber || "X" }}</td>
          <td id="contenttdtd">{{ id.startDate || "X" }}</td>
          <td id="contenttdtd">{{ id.expiryDate || "X" }}</td>
        </tr>
      </table>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectindex: -1,
      list: [],
      alllist: [],
      schoolList: [],
      allselectlist: [{ text: "전체 학급", value: -1 }],
      sort_start_key: false,
      sort_end_key: false,
    };
  },
  async created() {
    const list = await this.$store.dispatch("GET_ALL_CONTRACT");
    this.list = list.data;
    this.alllist = list.data;
    this.schoolList = [...new Set(this.list.map((row) => row.schoolName))];
    for (let i = 0; i < this.schoolList.length; i++) {
      this.allselectlist.push({ text: this.schoolList[i], value: i });
    }
  },
  methods: {
    changeallselect() {
      if (this.selectindex == -1) {
        this.list = this.alllist;
        return;
      }
      let filtered_list = [];
      for (let i = 0; i < this.alllist.length; i++) {
        if (
          this.alllist[i].schoolName !=
          this.allselectlist[this.selectindex + 1].text
        ) {
          continue;
        } else {
          filtered_list.push(this.alllist[i]);
        }
      }
      this.list = filtered_list;
    },
    sortbystart() {
      if (this.sort_start_key == false) {
        let result = this.list;
        result = result.sort(
          (a, b) => new Date(b.startDate) - new Date(a.startDate)
        );
        this.list = result;
        this.sort_start_key = true;
        return;
      }
      let result = this.list;
      result = result.sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      );
      this.list = result;
      this.sort_start_key = false;
      return;
    },
    sortbyend() {
      if (this.sort_end_key == false) {
        let result = this.list;
        result = result.sort(
          (a, b) => new Date(b.expiryDate) - new Date(a.expiryDate)
        );
        this.list = result;
        this.sort_end_key = true;
        return;
      }
      let result = this.list;
      result = result.sort(
        (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate)
      );
      this.list = result;
      this.sort_end_key = false;
      return;
    },
  },
};
</script>

<style>
</style>