import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './action'
import * as mutations from './mutation'
import { getSaveCookie } from '../cookie/cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: {
      msg: '',
      code: '',
      result: true,
      errorStatus: false
    },
    token: getSaveCookie('token2')
  },
  getters: {
    logCheck(state) {
      return state.token !== ''
    }
  },
  actions,
  mutations,
  modules: {}
})
