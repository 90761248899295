import { login, getidlist, getcontractlist } from '../api/api-list'

const LOGIN = async ({ commit }, loginData) => {
  const logind = { os: 'pc', id: loginData.id, password: loginData.password }
  const test = await login(logind)
  const check = loginData.check
  await commit('SET_LOGIN', { test, check })
}

const LOGOUT = async ({ commit }) => {
  await commit('SET_LOGOUT')
}

const GET_ALL_ID = async ({ commit }) => {
  const info = await getidlist()
  return info.data
}

const GET_ALL_CONTRACT = async ({ commit }) => {
  const info = await getcontractlist()
  return info.data
}

export { LOGIN, LOGOUT, GET_ALL_ID, GET_ALL_CONTRACT }
