<template>
  <div>
    <v-row style="margin-top: 30px" justify="center">
      <div style="width: 300px">
        <v-select
          v-model="selectindex"
          @change="changeallselect()"
          :items="allselectlist"
          :item-text="'text'"
          :item-value="'value'"
          outlined
        ></v-select>
      </div>
    </v-row>
    <v-row justify="center" style="margin-top: 30px">
      <table
        style="border-spacing: 0; width: 1500px; font-family: notosanslight"
      >
        <tr>
          <td id="contenttd">출판사</td>
          <td id="contenttd">연도</td>
          <td id="contenttd">accessLevel</td>
          <td id="contenttd">학교명</td>
          <td id="contenttd">학년</td>
          <td id="contenttd">반</td>
          <td id="contenttd">출석번호</td>
          <td id="contenttd">이름</td>
          <td id="contenttd">schoolLevel</td>
          <td id="contenttd">ID</td>
        </tr>
        <tr v-for="(id, i) in list" :key="i">
          <td id="contenttdtd">{{ id.publisher || "X" }}</td>
          <td id="contenttdtd">{{ id.year || "X" }}</td>
          <td id="contenttdtd">{{ id.accessLevel || "X" }}</td>
          <td id="contenttdtd">{{ id.schoolName || "X" }}</td>
          <td id="contenttdtd">{{ id.grade || "X" }}</td>
          <td id="contenttdtd">{{ id.classNum || "X" }}</td>
          <td id="contenttdtd">{{ id.attendanceNumber || "X" }}</td>
          <td id="contenttdtd">{{ id.name || "X" }}</td>
          <td id="contenttdtd">{{ id.schoolLevel || "X" }}</td>
          <td id="contenttdtd">{{ id.id || "X" }}</td>
        </tr>
      </table>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectindex: -1,
      schoolList: [],
      list: [],
      alllist: [],
      allselectlist: [{ text: "전체 조회", value: -1 }],
      testarr: [],
    };
  },
  async created() {
    const list = await this.$store.dispatch("GET_ALL_ID");
    this.alllist = list.data;
    this.list = list.data;
    this.schoolList = [...new Set(this.list.map((row) => row.schoolName))];
    for (let i = 0; i < this.schoolList.length; i++) {
      this.allselectlist.push({ text: this.schoolList[i], value: i });
    }
  },
  methods: {
    changeallselect() {
      if (this.selectindex == -1) {
        this.list = this.alllist;
        return;
      }
      let filtered_list = [];
      for (let i = 0; i < this.alllist.length; i++) {
        if (
          this.alllist[i].schoolName !=
          this.allselectlist[this.selectindex + 1].text
        ) {
          continue;
        } else {
          filtered_list.push(this.alllist[i]);
        }
      }
      this.list = filtered_list;
    },
  },
};
</script>

<style>
</style>